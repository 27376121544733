import React from 'react';
import "../../scss/main.scss"
import Header from '../../components/header';
import Main from '../../components/main';
import PageHeader from '../../components/Organism/PageHeader/PageHeader';
import { Helmet } from 'react-helmet';
import Footer from '../../components/Footer/Footer';
import StartWork from '../../components/forms/StartWork';
import Benefits from "../../components/Organism/benefits/benefits";
import Portfolio from "../../components/Organism/Portfolio/Portfolio";
import HowTo from "../../components/Organism/HowTo/HowTo";
import Cta from "../../components/Organism/Cta/Cta";
import Faq from "../../components/Organism/Faq/Faq";

function Corporate(props) {
    const form = <StartWork/>;
    return (
        <>
            <Helmet>
                <title>Разработка корпоративных сайтов с проработкой под вашу ца</title>
                <meta name="description" content="Разрабатываем корпоративные сайты и информационные порталы так, чтобы пользователь не потерялся на вашем сайте" />
            </Helmet>
            <Header/>
            <Main>
                <PageHeader h1={"Разработка корпоративных сайтов"} 
                    p={"Разрабатываем корпоративные сайты и информационные порталы так, чтобы пользователь не потерялся на вашем сайте"} modalButton modalContent={form}
                />
                <Benefits/>
                <HowTo header="Как мы создаем корпоративные сайты" bigText="Этапы разработки сайта компании"/>
                <Cta/>
                <Portfolio/>
                <Faq/>
            </Main>
            <Footer/>
        </>
    );
}

export default Corporate;